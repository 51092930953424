import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitch,
  faTwitter,
  faGooglePlay,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPodcast,
  faEnvelope,
  faRss,
} from "@fortawesome/free-solid-svg-icons";

import Logo from "url:./CDnDred.svg";

import casey from "url:./casey.png";
import cassie from "url:./cassie.png";
import simon from "url:./simon.png";
import kevin from "url:./kevin.png";
import hayden from "url:./hayden.png";

import DM from "url:./DM.png";
import Arcturian from "url:./Arcturian.png";
import MrKrillington from "url:./Mr Krillington.png";
import TomHanson from "url:./Tom Hanson.png";

import caseyVota from "url:./vota-Casey.png";
import cassieVota from "url:./vota-Cassie.png";
import simonVota from "url:./vota-Simon.png";
import kevinVota from "url:./vota-Kevin.png";
import haydenVota from "url:./vota-Hayden.png";

import caseyExpedition from "url:./Lee.png";
import simonExpedition from "url:./SirWiggles.png";
import kevinExpedition from "url:./Oubad.png";
import haydenExpedition from "url:./Seal.png";

import data from "./episodes.json";

type PersonProps = {
  name: string;
  character: string;
  image: any;
  sketch: any;
};

const Person = ({ name, character, image, sketch }: PersonProps) => (
  <div className="person">
    <div className="wrap">
      <div>
        <img className="real" src={image} />
        <p>{name}</p>
      </div>
      <div>
        <img className="sketch" src={sketch} />
        <p>{character}</p>
      </div>
    </div>
  </div>
);

export const App = () => {
  return (
    <React.Fragment>
      <div className="content">
        <div className="container">
          <div className="header">
            <img className="dm bounce" src={haydenExpedition} />
            <img className="logo" src={Logo} />
            <div className="players">
              <img className="player bounce y" src={caseyExpedition} />
              <img className="player bounce k" src={kevinExpedition} />
              <img className="player bounce i" src={simonExpedition} />
            </div>
          </div>
          <p>
            Comedy &amp; D: Streaming live every Thursday at 8pm Pacific on{" "}
            <a href="https://www.twitch.tv/ComeDyAndD">
              <FontAwesomeIcon icon={faTwitch} />
              Twitch
            </a>
            .
          </p>
          <p>
            <form
              action="https://tinyletter.com/ComeDyAndD"
              method="post"
              target="popupwindow"
            >
              <div>
                <label htmlFor="tlemail">
                  Subscribe to the ComeDy&D newsletter:
                </label>
              </div>
              <input
                type="text"
                name="email"
                id="tlemail"
                placeholder="Your email..."
              />
              <input type="hidden" value="1" name="embed" />
              <input type="submit" value="Subscribe" />
            </form>
          </p>
          <div className="social">
            We're everywhere:
            <span className="group">
              <a className="highlight" href="https://www.twitch.tv/ComeDyAndD">
                <FontAwesomeIcon icon={faTwitch} />
              </a>
              <a
                className="highlight"
                href="https://www.youtube.com/channel/UCeAAzDUf1C8MjpFE4SYYSIw"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a
                className="highlight"
                href="https://twitter.com/ComedyandDshow"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a className="highlight" href="https://tinyletter.com/ComeDyAndD">
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
              <a
                className="highlight"
                href="https://comedyandd.com/podcast/feed.rss"
              >
                <FontAwesomeIcon icon={faRss} />
              </a>
              <a
                className="highlight"
                href="https://podcasts.apple.com/us/podcast/comedy-d/id1510649758"
              >
                <FontAwesomeIcon icon={faPodcast} />
              </a>
              <a
                className="highlight"
                href="https://play.google.com/music/listen#/ps/Iueex3sqtmaxqmkqszxzoyqf7si"
              >
                <FontAwesomeIcon icon={faGooglePlay} />
              </a>
              <a
                className="highlight"
                href="https://open.spotify.com/show/2iGW2sOLZRgJZRIZoebboF"
              >
                <FontAwesomeIcon icon={faSpotify} />
              </a>
            </span>
          </div>
          <br />
          {(data.campaigns || [])
            .slice(0)
            .reverse()
            .map((c) => (
              <div className="campaign" key={c.campaign}>
                <div>
                  <h1>"{c.name}" Campaign</h1>
                </div>
                {c.campaign === 1 && (
                  <div>
                    <h2>Cast:</h2>
                    <Person
                      name="Kevin O'Shea"
                      character="Dungeon Master"
                      image={kevin}
                      sketch={DM}
                    />
                    <Person
                      name="Cassie B"
                      character="Mr. Krillington"
                      image={cassie}
                      sketch={MrKrillington}
                    />
                    <Person
                      name="Casey Muller"
                      character="Arcturian Boötis"
                      image={casey}
                      sketch={Arcturian}
                    />
                    <Person
                      name="Simon Timony"
                      character="Ofc. Tom Hanson"
                      image={simon}
                      sketch={TomHanson}
                    />
                  </div>
                )}
                {c.campaign === 2 && (
                  <div>
                    <h2>Cast:</h2>
                    <Person
                      name="Simon Timony"
                      character="DM"
                      image={simon}
                      sketch={simonVota}
                    />
                    <Person
                      name="Cassie B"
                      character="Neerg"
                      image={cassie}
                      sketch={cassieVota}
                    />
                    <Person
                      name="Kevin O'Shea"
                      character="Regginton"
                      image={kevin}
                      sketch={kevinVota}
                    />
                    <Person
                      name="Casey Muller"
                      character="Celebsuni"
                      image={casey}
                      sketch={caseyVota}
                    />
                    <Person
                      name="Hayden Greif-Neill"
                      character="Kr"
                      image={hayden}
                      sketch={haydenVota}
                    />
                  </div>
                )}
                {c.campaign === 3 && (
                  <div>
                    <h2>Cast:</h2>
                    <Person
                      name="Hayden Greif-Neill"
                      character="DM"
                      image={hayden}
                      sketch={haydenExpedition}
                    />
                    <Person
                      name="Simon Timony"
                      character="Sir Wiggles"
                      image={simon}
                      sketch={simonExpedition}
                    />
                    <Person
                      name="Kevin O'Shea"
                      character="Oubad Rabab"
                      image={kevin}
                      sketch={kevinExpedition}
                    />
                    <Person
                      name="Casey Muller"
                      character="Mary 'Lee' Shelley"
                      image={casey}
                      sketch={caseyExpedition}
                    />
                  </div>
                )}
                <h2>Episodes:</h2>
                {c.episodes.map(
                  (e) =>
                    e.youtube && (
                      <div className="video" key={e.episode}>
                        <div className="wrap">
                          <iframe
                            src={`https://www.youtube.com/embed/${e.youtube}`}
                            width="560"
                            height="315"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="video"
                          />
                          <div className="title">
                            Episode {e.episode}: {e.title}
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            ))}
          <p className="thanks">
            Special thanks to Gamescape for their support and Syrinscape for
            their amazing sound.
            <div>Character art: TheArtofSilent (Terra Reed)</div>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
