{
  "campaigns": [
    {
      "campaign": 1,
      "name": "Terra Reed",
      "episodes": [
        {
          "episode": 0,
          "title": "The Half Elf, The Unicycle Thief, The Penguin and His Rocks",
          "summary": "Three intrepid travelers meet and begin their journey together!",
          "date": "2020-02-26",
          "mp3_duration": "2:08:53",
          "mp3_size": 154666369,
          "youtube": "lke5Erz-hfA"
        },
        {
          "episode": 1,
          "title": "Total Party Kobold",
          "summary": "The frontier town awaits but danger lurks. Will the team make it?",
          "date": "2020-03-4",
          "mp3_duration": "1:29:11",
          "mp3_size": 107030516,
          "youtube": "A75-tklMqxY"
        },
        {
          "episode": 2,
          "title": "Love and Real Estate",
          "summary": "The New World holds as much opportunity as it does danger. How will the three work together to succeed?",
          "date": "2020-03-11",
          "mp3_duration": "1:37:15",
          "mp3_size": 116706925,
          "youtube": "iYSJfxrP2Ok"
        },
        {
          "episode": 3,
          "title": "Act like you've been there",
          "summary": "The new adventurers are tasked with slaughtering a town of Kobolds. Will they appease the guild or have a change of heart?",
          "date": "2020-03-18",
          "mp3_size": 113251822,
          "mp3_duration": "1:34:22",
          "youtube": "fdRHTvaVKOc"
        },
        {
          "episode": 4,
          "title": "Secrets & Eyes",
          "summary": "The adventurers leave Kobold Town but will have to face their deepest fears before returning home.",
          "date": "2020-03-25",
          "mp3_size": 103396883,
          "mp3_duration": "1:26:09",
          "youtube": "dZ3BCIIFmyM"
        },
        {
          "episode": 5,
          "title": "Hag Ten",
          "summary": "The party travels to a small seaside town with a big time hag problem (NO SEAGULS WERE HARMED IN THE MAKING OF THIS EPISODE)",
          "date": "2020-04-01",
          "mp3_size": 125349666,
          "mp3_duration": "1:44:27",
          "youtube": "54FgzTIsqOc"
        },
        {
          "episode": 6,
          "title": "Laceration, Laceration, Laceration",
          "summary": "The gang has a run-in with a realtor and explore the salty underbelly of the small fishing village.",
          "date": "2020-04-08",
          "mp3_size": 85365601,
          "mp3_duration": "01:11:08",
          "youtube": "54FgzTIsqOc"
        },
        {
          "episode": 7,
          "title": "Shadows, Nothics, and Chad Randall",
          "summary": "The gang just made their way back to River Glen Falls after annihilating Henrietta the Hag in Oceanview.  Not even a day passed before they encountered more eerie shadows at the Kenku's Nest and a group of nothics at the Guild Hall. Will they save another town or become victims of another TPK?",
          "date": "2020-04-15",
          "mp3_size": 111469237,
          "mp3_duration": "01:32:53",
          "youtube": "RZkVrjL7MZI"
        },
        {
          "episode": 8,
          "title": "Are you afraid of the darkness?",
          "summary": "The crew infiltrated the Guild Hall after an accidental fire and gathered info before their big trip to the Doomsgate. Will they be able to find and stop the darkness?",
          "date": "2020-04-22",
          "youtube": "tPrs4B3V2_Q"
        },
        {
          "episode": 9,
          "title": "The Doomsgate",
          "summary": "The team become separated and realize they are each trapped in different rooms. After escaping they run into a series of horrors.  Will all of Doomsgate be a chilling puzzle?",
          "date": "2020-04-29",
          "youtube": "dugYYJI5IMk"
        },
        {
          "episode": 10,
          "title": "The Raven Queen and The Wheel Man",
          "summary": "Will Arcturian, Tom, and Mr. Krillington defeating the two shadow demons? Will Krill also find the answer to global warming? The team makes their way to the Raven Queen while finding the answer to the ending the shadow demons. They meet their dopplegangers and stumble upon a Wheel Man.",
          "date": "2020-05-06",
          "youtube": "QamHd8vEyPA"
        },
        {
          "episode": 11,
          "title": "The Unicycle Wheel",
          "summary": "The three adventurers continued to the Doomgate to save River Glen Falls and were then attacked by shadows. We fell into a well and ran into an elf who opened the gate to find the cure to her painful connection to the shadow realm. We found the Wheel Man's carcass and looted his body as well as his room. Then we ran into the darker doppleganger versions of ourselves...",
          "date": "2020-05-13",
          "youtube": "PJ1yr8HzH5g"
        },
        {
          "episode": 12,
          "title": "The Three Doors",
          "summary": "The team finds three doorways at the end of the dark hall. Will they find Sarz and Karz or will they meet more demons?",
          "date": "2020-05-20",
          "youtube": "0JRTzayvjBg"
        },
        {
          "episode": 13,
          "title": "The Underdark",
          "summary": "Will the Duergar see past our disguises? The team end up in Gorwolf, a town in the Underdark and find themselves in a Battle of the Bands.",
          "date": "2020-05-27",
          "youtube": "LRT7yr875sc"
        },
        {
          "episode": 14,
          "title": "The Ghostwise Halfling",
          "summary": "Mr. Krillington, Arcturian, and Officer Tom Hansen try to find a way out of the Underdark after a gig at Misses Spider's Web.",
          "date": "2020-06-03",
          "youtube": "-QT9AF2R3Tg"
        },
        {
          "episode": 15,
          "title": "Misses Spider's Web",
          "summary": "We start off with our gig and unwelcome guests appear who seem particularly interested in Arcturian...",
          "date": "2020-06-10",
          "youtube": "8ur1ZN8CtwM"
        },
        {
          "episode": 16,
          "title": "",
          "summary": "",
          "date": "2020-06-17"
        },
        {
          "episode": 17,
          "title": "",
          "summary": "",
          "date": "2020-06-24"
        },
        {
          "episode": 18,
          "title": "",
          "summary": "",
          "date": "2020-07-01"
        },
        {
          "episode": 19,
          "title": "",
          "summary": "",
          "date": "2020-07-08"
        },
        {
          "episode": 20,
          "title": "",
          "summary": "",
          "date": "2020-07-15"
        },
        {
          "episode": 21,
          "title": "",
          "summary": "",
          "date": "2020-07-22"
        },
        {
          "episode": 22,
          "title": "",
          "summary": "",
          "date": "2020-07-29"
        },
        {
          "episode": 23,
          "title": "",
          "summary": "",
          "date": "2020-08-05"
        },
        {
          "episode": 24,
          "title": "",
          "summary": "",
          "date": "2020-08-12"
        },
        {
          "episode": 25,
          "title": "",
          "summary": "",
          "date": "2020-08-19"
        },
        {
          "episode": 26,
          "title": "",
          "summary": "",
          "date": "2020-08-26"
        }
      ]
    },
    {
      "campaign": 2,
      "name": "Voices of the Air",
      "episodes": [
        {
          "episode": 1,
          "title": "Has Anyone Seen My Missing Eye?",
          "summary": "The city of Alya Dór has been going through some freaky changes. A crater explodes and monsters come out to kill. People with giant eyeball heads arrive, and the state of art, fashion, and crime are all the rage.\nHere, Elf Cleric Celubsuni and Kobold investigator Reggington meet in the office of Alya Guard Chief Kendrick Dean who drafts them into The Deputies to solve a murder in the Artist’s Belt. Later, they meet Half-Drow Warlock Kr who takes an interest in the death and tags along to ”help.”\n​Ralph’s Records own H and Lillip discuss events leading up to the death. Their one request is that Neerg, a Bard, friend, and one of the humanoid Residents with a giant eyeball head, assist them.\n​Was it a murder? Will they find the culprit? How will a snobby Elf, and sticky-fingered Kobold, a 3,000 year old dead god and an eyeball head get along? Tune in to ComeDy&D: Voices of the Air to find out.",
          "date": "2021-01-06",
          "mp3_duration": "2:07:31",
          "mp3_size": 183626942,
          "youtube": "r5jn3GqrbaM"
        },
        {
          "episode": 2,
          "title": "I'm not a mine reader",
          "summary": "Reggington, Celebsuni, Neerg and Kr are The New Deputies. No, not a bad new wave cover band, they're Alya Dór's latest hope for justice. The team is sent to investigate a recent robbery at the mines. En route they will confront the horrors of global climate change, get spooked by actual wind, and try to progress in a world with manners.",
          "date": "2021-01-13",
          "mp3_duration": "01:58:59",
          "mp3_size": 171329538,
          "youtube": "DgXnuhqn0-c"
        },
        {
          "episode": 3,
          "title": "Baron it all",
          "summary": "The team gets permission to search Baron Madra’s mansion and do what you’d expect. Ever wonder where a noble keeps their scarfs? Wonder no more! A snooping-they-will-go. Later take a trip to the Dwarven enclave where death wishes are given out like candy.",
          "date": "2021-01-20",
          "mp3_duration": "01:52:44",
          "mp3_size": 162325443,
          "youtube": "_pkUObwifsA"
        },
        {
          "episode": 4,
          "title": "Cookin' the Books",
          "summary": "After meeting some freaky Dwarfs and killing innocent dire wolves who only wanted to feed on their faces, The Deputies decide what to do with the new knowledge that Baron Faire Madra has been stealing from the king. They've discovered a carriage with a small fortune in excellent ore. Will they take it back to Baron Madra? Will they come to the Dwarves for guidance? Will Kr finally fulfil his killing Ned kink? Find out on 'Cookin the Books.'",
          "date": "2021-02-03",
          "mp3_duration": "01:48:03",
          "mp3_size": 155582090,
          "youtube": "PDml03u6ums"
        },
        {
          "episode": 5,
          "title": "Drop Dead Ned",
          "summary": "The final showdown between Ned and Kr.",
          "date": "2021-02-10",
          "mp3_duration": "02:03:08",
          "mp3_size": 177300503,
          "youtube": "QrjCw76TT1M"
        },
        {
          "episode": 6,
          "title": "I Have the Black Lung",
          "summary": "No but seriously, it's a real disease.",
          "date": "2021-02-17",
          "mp3_duration": "01:48:20",
          "mp3_size": 155983958,
          "youtube": "D8xSMNhhgkA"
        },
        {
          "episode": 7,
          "title": "Mama Said Nanoc You Out",
          "summary": "After literally blowing away any of the Barons opposition and sacking his trophy room, the New Deputies trek back to Alya Dór, where they intend to drop off the fine ore haul to Nanoc the armor. But as if common they should able to continue on with no problem and even less trouble, right?",
          "date": "2021-02-24",
          "mp3_duration": "01:32:54",
          "mp3_size": 111469237,
          "youtube": "GBM4nObRbyw"
        },
        {
          "episode": 8,
          "title": "Dwarven Mines",
          "summary": "Kr is the mayor of a ghost town full of lizards and it goes downhill from there. The team back tracks to the future and head to the Baron’s mansion. Celebsuni performs a ceremony of marital matrimony of long parted lovers. The team head to the mines and go surfing. What will they find? Hint: it’s big.",
          "date": "2021-03-03",
          "mp3_duration": "01:48:41",
          "mp3_size": 156493032,
          "youtube": "PXOs75oe93s"
        },
        {
          "episode": 9,
          "title": "Fluffies Everywhere",
          "summary": "Politicking ain’t easy, just ask Kr, mayor of a ghost town full of lizards. In the mines they come across a secret kept under Alya Dör which could change the world forever. A wise man said: “Carry dog treats, for the wolves are hungry.”",
          "date": "2021-03-10",
          "mp3_duration": "01:55:46",
          "mp3_size": 166707745,
          "youtube": "mTTqbgq80zQ"
        },
        {
          "episode": 10,
          "title": "Galosh, This is Going to be Fun",
          "summary": "Put on your snow boots because we’re going up north! The Mohilar have given the team enough clues to unravel the mystery which leads them to the snowy mountains. Find out if they end up where they wanted to go or if mad frostbite awaits.",
          "date": "2021-03-17",
          "mp3_duration": "01:45:22",
          "mp3_size": 151730805,
          "youtube": "ArTv6x1tgUc"
        },
        {
          "episode": 11,
          "title": "Go Big or Kobold",
          "summary": "Remember, if you’re a guest at someone’s home and they think one of you is a god, just go with it. The team makes nice with their new Goliath friends but quickly get caught in an awkward situation, kind of like when you’re the third wheel on a date and the couple is trying to kill each other and one of them has a secret sheet music that could possibly save the world... Stay tuned to the end and witness possibly the most clutch moment in any D&D game you could possibly imagine.",
          "date": "2021-03-24",
          "mp3_duration": "01:45:18",
          "mp3_size": 151621091,
          "youtube": "gjXI6P-iYgs"
        }
      ]
    },
    {
      "campaign": 3,
      "name": "Expedition to the North",
      "episodes": [
        {
          "episode": 1,
          "title": "First",
          "summary": "The first episode of Expedition to the North!",
          "date": "2021-06-30",
          "youtube": "0NHJ60Gt378"
        },
        {
          "episode": 2,
          "title": "Second",
          "summary": "The second episode of Expedition to the North!",
          "date": "2021-07-08",
          "youtube": "RgbXu_y1yTc"
        },
        {
          "episode": 3,
          "title": "Third",
          "summary": "The third episode of Expedition to the North!",
          "date": "2021-07-15",
          "youtube": "RpURyerCmSA"
        },
        {
          "episode": 4,
          "title": "Fourth",
          "summary": "The fourth episode of Expedition to the North!",
          "date": "2021-07-29",
          "youtube": "agEjNGjQ0U0"
        },
        {
          "episode": 5,
          "title": "Fifth",
          "summary": "The fifth episode of Expedition to the North!",
          "date": "2021-08-05",
          "youtube": "fGMplshyu4U"
        },
        {
          "episode": 6,
          "title": "Sixth",
          "summary": "The sixth episode of Expedition to the North!",
          "date": "2021-08-12",
          "youtube": "lbb58kUzJAo"
        },
        {
          "episode": 7,
          "title": "Seventh",
          "summary": "The seventh episode of Expedition to the North!",
          "date": "2021-08-19",
          "youtube": "-ZH5bK-95co"
        },
        {
          "episode": 8,
          "title": "Eigth",
          "summary": "The eigth episode of Expedition to the North!",
          "date": "2021-08-26",
          "youtube": "KWezMRMaIO8"
        },
        {
          "episode": 9,
          "title": "Ninth",
          "summary": "The ninth episode of Expedition to the North!",
          "date": "2021-09-02",
          "youtube": "dfwBIhLJ5yA"
        },
        {
          "episode": 10,
          "title": "Tenth",
          "summary": "The tenth episode of Expedition to the North!",
          "date": "2021-09-09",
          "youtube": "qLv7KMa8nf4"
        }
      ]
    }
  ]
}
